/* PotatoHead Font */
@font-face {
    font-family: 'cute';
    src: url('https://storage.googleapis.com/miniapp-resources/Potato/SpicyRice-Regular.ttf');
}

.tater {
    font-family: 'cute', sans-serif;
}

.title {
    color: #8b5cf6;
    font-family: 'cute', sans-serif;
    font-weight: bolder;
    font-size: 2rem;
    text-shadow: 2px 2px 0 black;
 }

.menu-item {
    font-family: 'cute', sans-serif;
    font-weight: 900;
    font-size: 1.5rem;
}


.display {
    width: 17rem;
    height: 19rem;
}
    
@media only screen and (min-width: 750){
    .display {
        width: 30rem;
        height: 30rem;
    }

}