@font-face {
  font-family: "MagicCard";
  src: url("https://storage.googleapis.com/miniapp-resources/magicCard/fonts/mplantin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "MagicCard";
  src: url("https://storage.googleapis.com/miniapp-resources/magicCard/fonts/ModMatrix.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "MagicCard";
  src: url("https://storage.googleapis.com/miniapp-resources/magicCard/fonts/mplantinit.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
