.save-modal-button {
    width: 100%;
    height: 100%;
    max-height: '60px';
    font-size: 16px;
    font-weight: bold;
    background-color: #0051E8;
    color: #FFFFFF;
    font-family: "Public Sans";
    border: none;
    text-align: "center";
}

.save-modal-button:hover {
    background-color: #CCFE07;
    color: black;
}

#scroll::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
}

#scroll {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

@keyframes blink {
    0%,
    6.67%,
    100% {
        height: 20px;
    }
    3.33% {
        height: 5px;
    }
}

@font-face {
    font-family: "SeamCommunityFont-Regular";
    src: url("https://storage.googleapis.com/miniapp-resources/JustAThought/SeamCommunityFont-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}