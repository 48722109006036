

  .LayeredImageBlock-slider {  
    -webkit-appearance: none;  
    -moz-appearance: none;    
    -ms-appearance: none;      
    appearance: none;          
    padding: 0px;
    margin: 0px;
    width: 90%;
    height: 10px;
    border-radius: 20px;
    margin-right: 12px;
    flex-grow: 0;
    Border: 2px solid #0051E8;
  }

  .LayeredImageBlock-slider::-webkit-slider-thumb {
    -webkit-appearance: none;  
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: #0051E8;
  }

  .LayeredImageBlock-slider::-moz-range-thumb {
    width: 20px;               
    height: 20px;
    border-radius: 10px;
    background: #0051E8;
    border: none;              
  }

  .LayeredImageBlock-slider::-ms-thumb {
    width: 20px;              
    height: 20px;
    border-radius: 10px;
    background: #0051E8;
    border: none;             
  }

  .LayeredImageBlock-slider::-ms-track {
    width: 100%;
    height: 10px;
    border-radius: 20px;
    background: transparent;  
    border-color: transparent;
    color: transparent;
  }

  .LayeredImageBlock-slider::-ms-fill-lower {
    background: #0051E8;      
    border-radius: 20px;
  }

  .LayeredImageBlock-slider::-ms-fill-upper {
    background: #0051E8;
    border-radius: 20px;
  }

  .hideScroll::-webkit-scrollbar {
    display: none;
  }

  .hideScroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .hideScreenSmall {
    display: inline-block;
  }

  .img-card{
  width: 100%;
  background-color: #e4e6eb;
  padding: 8px 16px;
  border-radius: 8px;
  margin: 7px 0px 16px 0px;
  opacity: 0.999;  
  }

  .outline-card{
    outline: 2px solid #2050DF;
  }

  .mode-button {
    flex: 1;
    width: 100%;
    height: "auto";
    padding: 12px 16px 12px 16px;
    margin: 4px 0px;
    text-align: left;
    overflow-wrap: break-word;
    white-space: normal;
    border-radius: 8px;
    background-color: white;
    color: black;
    border-width: 1px;
    border-style: solid;
    border-color: #2050DF;
  }

  .mode-button-highlight {
    background-color: #2050DF;
    color: #ffffff;
  }


  @media only screen and (max-width:375px) {

    .hideScreenSmall {
      display: none;
    }




  }
