h1 {
  font-size: 32px;
  margin: 0;
  font-weight: bold;
}

h2 {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}

h3 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

h4 {
  font-size: 16px;
  margin: 0;
}

.editor-alignment-center div {
  text-align: center;
}

.editor-alignment-right div {
  text-align: right;
}