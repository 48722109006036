ion-tab-bar {
  background: black;
  bottom: 29px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  border-radius: 32px;
  position: fixed;
}

ion-tab-button {
  background-color: black;
  --color: "#d4d4d4";
  --color-selected: white;

  &::before {
    background-color: transparent;
    display: block;
    width: 20px;
    height: 2px;
  }
}